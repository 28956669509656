import {Fields, Query} from '@vuex-orm/core';
import {Joined} from '@/orm/types/Joined';
import * as TaskTopicTagApi from '@/tasks/api/orm/TaskTopicTagApi';
import {StembleModel} from '@/common/models/StembleModel';

/**
 * TaskTopicTag class
 */
export class TaskTopicTag extends StembleModel {
  static entity = 'TaskTopicTag';

  id!: number;
  title!: string;
  color!: string;
  rank!: number;
  parentId!: number;
  parent!: Joined<TaskTopicTag>;
  children!: Joined<TaskTopicTag[]>;

  static fields(): Fields {
    return {
      id: this.number(null),
      title: this.string(null),
      color: this.string(null),
      rank: this.number(1),
      parentId: this.number(null).nullable(),
      parent: this.belongsTo(TaskTopicTag, 'parentId'),
      children: this.hasMany(TaskTopicTag, 'parentId'),
    };
  }

  static get api() {
    return TaskTopicTagApi;
  }

  static fullQuery(): Query<TaskTopicTag> {
    return this.query();
  }
}

export default TaskTopicTag;
