import {AppMode} from '@/common/enums/AppMode';

export interface LegacyFrontendEnvironment {
  buildSha: string;
  mode: AppMode;
  stripeKey: string;
  gaTrackingId?: string;
  hubspotId?: string;
  refinerSimCsatId?: string;
  refinerProject?: string;
  sentryDsn?: string;
}

function getBuildSha(): string {
  return process.env.VUE_APP_BUILD_SHA ?? 'latest';
}

const productionEnv: LegacyFrontendEnvironment = {
  buildSha: getBuildSha(),
  mode: AppMode.production,
  stripeKey:
    'pk_live_51GxeLfH4Oj328smsq0qPGv1g6GKambMQSii62ZcWoXUF55BQpImrdaTLhWXQODetm9miqkILqdGJbJ5p3TvbKk9o00xXj0EMh4',
  gaTrackingId: 'UA-157984217-1',
  hubspotId: '9143538',
  refinerSimCsatId: '9041e8a0-376d-11ec-8ef8-4f4d92c1543f',
  refinerProject: '90379f10-376d-11ec-9d3f-87ae29cc657b',
  sentryDsn:
    'https://7e5a1fe66a952292adf485f5c775f645@o4506123176837120.ingest.sentry.io/4506123577655296',
};

const stagingEnv: LegacyFrontendEnvironment = {
  buildSha: getBuildSha(),
  mode: AppMode.staging,
  stripeKey:
    'pk_test_51GxeLfH4Oj328smswn9w3NUBiYRB7SexzEzqjarkzVN7xWaPuZRJc99SlteNZ97ratDjK6TYegGFURDqNqH2aFFN00GEudHPJ3',
  hubspotId: '9143538',
  refinerSimCsatId: '9041e8a0-376d-11ec-8ef8-4f4d92c1543f',
  refinerProject: '90379f10-376d-11ec-9d3f-87ae29cc657b',
  sentryDsn:
    'https://7e5a1fe66a952292adf485f5c775f645@o4506123176837120.ingest.sentry.io/4506123577655296',
};

const developmentEnv: LegacyFrontendEnvironment = {
  buildSha: getBuildSha(),
  mode: AppMode.development,
  stripeKey:
    'pk_test_51GxeLfH4Oj328smswn9w3NUBiYRB7SexzEzqjarkzVN7xWaPuZRJc99SlteNZ97ratDjK6TYegGFURDqNqH2aFFN00GEudHPJ3',
  refinerSimCsatId: '9041e8a0-376d-11ec-8ef8-4f4d92c1543f',
  refinerProject: '90379f10-376d-11ec-9d3f-87ae29cc657b',
  // Uncomment to test Sentry integration
  // sentryDsn: "https://7e5a1fe66a952292adf485f5c775f645@o4506123176837120.ingest.sentry.io/4506123577655296"
};

export function getEnvironment(): LegacyFrontendEnvironment {
  switch (window.location.hostname) {
    case 'localhost':
      return developmentEnv;
    case 'app.staging.stemble.ca':
    case 'app.staging.stemble.com':
      return stagingEnv;
    case 'app.stemble.ca':
    case 'app.stemble.com':
      return productionEnv;
    default:
      return productionEnv;
  }
}

export function isDevelopment(): boolean {
  return getEnvironment().mode === 'development';
}
