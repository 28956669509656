import {Model, Query} from '@vuex-orm/core';
import User from '../../users/models/User';
import TaskAssignment from '../../tasks/models/TaskAssignment';
import {Course, CourseSection} from '@/courses/models';

import Task from '../../tasks/models/Task';
import CourseLikeAssignment from './CourseLikeAssignment';
import {Joined} from '@/orm/types/Joined';
import * as AssignmentApi from '@/assignments/api/orm/AssignmentApi';
import {IAssignmentQueryParameters} from '@/assignments/types/IAssignmentQueryParameters';
import Fields from '@vuex-orm/core/lib/model/contracts/Fields';
import {RawAssignment} from '@/assignments/types/RawAssignment';

/**
 * Assignment class
 */
export class Assignment
  extends Model
  implements Omit<RawAssignment, 'createdBy' | 'taskAssignments'>
{
  static entity = 'Assignment';

  id!: number;
  name!: string;
  courseId!: number;
  course!: Joined<Course>;
  ownerId!: number;
  owner!: Joined<CourseSection>;
  createdAt!: string;
  createdById!: number | null;
  createdBy!: Joined<User>;
  taskAssignments!: Joined<TaskAssignment[]>;
  courseLikeAssignments!: Joined<CourseLikeAssignment[]>;
  overviewText!: string | null;

  static fields(): Fields {
    return {
      id: this.number(null),
      name: this.string(''),
      courseId: this.number(null),
      course: this.belongsTo(Course, 'courseId'),
      ownerId: this.number(null),
      owner: this.belongsTo(CourseSection, 'ownerId'),
      createdAt: this.string(''),
      createdById: this.number(null).nullable(),
      createdBy: this.belongsTo(User, 'createdById'),
      taskAssignments: this.hasMany(TaskAssignment, 'assignmentId'),
      courseLikeAssignments: this.hasMany(CourseLikeAssignment, 'assignmentId'),
      overviewText: this.string('').nullable(),
    };
  }

  get tasks() {
    return this.taskAssignments.map((item) => {
      return item.task;
    });
  }

  get pointsAvailable() {
    return this.taskAssignments.reduce((acc, taskAssignment) => {
      return acc + taskAssignment.pointValue;
    }, 0);
  }

  get assignedCourseLikeIds() {
    const result = [];
    for (const courseLikeAssignment of this.courseLikeAssignments) {
      result.push(courseLikeAssignment.courseLikeId);
    }
    return result;
  }

  static get api() {
    return AssignmentApi;
  }

  /**
   * Query method to properly load an assignment
   * @returns {Query<Assignment>}
   */
  static fullQuery(options: IAssignmentQueryParameters = {}) {
    let q: Query<Assignment> = options.query ?? this.query();

    if (options.course ?? true) {
      q = q.with('course');
    }
    if (options.createdBy) {
      q = q.with('createdBy');
    }
    if (options.owner) {
      q = q.with('owner');
    }
    if (options.courseLikeAssignments) {
      q = q.with('courseLikeAssignments', (q2) =>
        CourseLikeAssignment.fullQuery({
          ...options.courseLikeAssignmentQueryParams,
          query: q2 as Query<CourseLikeAssignment>,
        })
      );
    }
    if (
      options.taskAssignments ||
      options.taskContent ||
      options.taskStates ||
      options.responses ||
      options.grades
    ) {
      q = q.with('taskAssignments', (query1) => {
        return query1.orderBy('orderingIndex').with('task', (query2) =>
          Task.fullQuery({
            taskStates: options.taskStates,
            topicTags: options.topicTags,
            responses: options.responses,
            feedback: options.feedback,
            grades: options.grades,
            query: query2 as Query<Task>,
          })
        );
      });
    }

    return q;
  }
}

export default Assignment;
