// @ts-ignore
import _refiner from 'refiner-js';
import User from '@/users/models/User';
import {makeGlobalSingleton} from '@/container';

export const RefinerService = makeGlobalSingleton(() => makeRefinerService());

function makeRefinerService() {
  function setProject(projectId: string) {
    _refiner('setProject', projectId);
  }

  function identifyUser(user: User) {
    _refiner('identifyUser', {
      id: user.id,
      email: user.email,
      name: user.fullName,
    });
  }

  function addToResponse(additionalData: object) {
    _refiner('addToResponse', additionalData);
  }

  function showForm(formId: string, force: boolean = false) {
    _refiner('showForm', formId, force);
  }

  function closeForm(formId: string) {
    _refiner('closeForm', formId);
  }

  function resetUser() {
    _refiner('resetUser');
  }

  return {
    setProject,
    identifyUser,
    showForm,
    closeForm,
    addToResponse,
    resetUser,
  };
}
