export enum Ability {
  // Course Admin
  ViewCourseAdmin = 'view.courseAdmin',

  // Courses
  CreateCourses = 'create.courses',
  UpdateCourses = 'update.courses',
  DeleteCourses = 'delete.courses',
  ViewCourses = 'view.courses',
  ViewCourseRosters = 'view.course.rosters',
  UpdateCourseRosters = 'update.course.rosters',

  // Sections
  CreateSections = 'create.sections',

  // Users
  ViewUserInformation = 'view.user.information',
  ViewUserEmailAddresses = 'view.user.email_addresses',

  // Institutions
  ViewInstitutions = 'view.institutions',

  // Events
  ViewEventRosters = 'view.event.rosters',
  ViewEvents = 'view.events',
  DeleteEvents = 'delete.events',
  CreateEvents = 'create.events',
  UpdateEvents = 'update.events',
  UpdateEventRosters = 'update.event.rosters',

  ViewEventSchedules = 'view.event.schedules',
  CreateEventSchedules = 'create.event.schedules',
  UpdateEventSchedules = 'update.event.schedules',
  DeleteEventSecuels = 'delete.event.schedules',

  // Questions and Tasks
  CreateTasks = 'create.tasks',
  DeleteTasks = 'delete.tasks',
  UpdateTasks = 'update.tasks',
  ViewTasks = 'view.tasks',
  ReissueQuestionStates = 'reissue.question_states',
  ViewIssuedTaskStates = 'view.issued.task.states',

  // Topic tags
  CreateTopicTags = 'create.topic.tags',
  DeleteTopicTags = 'delete.topic.tags',

  // Assignments
  CreateAssignments = 'create.assignments',
  ViewOwnedAssignments = 'view.owned.assignments',
  UpdateAssignments = 'update.assignments',
  DeleteAssignments = 'delete.assignments',
  TestAssignments = 'test.assignments',

  // CourseLikeAssignments
  AssignCourseLikeAssignments = 'assign.course_like_assignments',
  ViewCourseLikeAssignments = 'view.course_like_assignments',

  // Assignment extensions
  ViewAssignmentExtensions = 'view.assignment.extensions',
  GrantAssignmentExtensions = 'grant.assignment.extensions',
  DeleteAssignmentExtensions = 'delete.assignment.extensions',
  RevokeAssignmentExtensions = 'revoke.assignment.extensions',

  // Locations
  CreateLocations = 'create.locations',
  ViewLocations = 'view.locations',
  UpdateLocations = 'update.locations',
  DeleteLocations = 'delete.locations',

  // Grades
  ViewGrades = 'view.grades',
  SubmitGrades = 'submit.grades',

  // Responses
  ViewResponses = 'view.responses',
  SubmitResponses = 'submit.responses',
  TryTasks = 'try.tasks',

  // Payment Requests
  ViewPaymentRequests = 'view.payment.requests',

  //Analytics
  ViewAnalytics = 'view.analytics',
}
