import {inject} from '@/container';
import {AuthService} from '@/auth/services/authService';
import User from '@/users/models/User';
import {DependencyType} from '@/container/types/DependencyType';
import {RefinerService} from '@/onboarding/services/RefinerService';
import {getEnvironment} from '@/environment';
import {IVueAnalyticsPluginOptions} from '@/telemetry/plugins/vue-analytics/IVueAnalyticsPluginOptions';

let refinerService: DependencyType<typeof RefinerService> | null = null;

export function install(Vue: Vue, {auth}: IVueAnalyticsPluginOptions) {
  const projectId = getEnvironment().refinerProject;
  if (projectId) {
    refinerService = inject(RefinerService);
    refinerService.setProject(projectId);
    setupAuth(auth);
  }
}

/**
 * Sets up identify and reset calls when auth events happen
 * @param authService
 */
const setupAuth = (authService: AuthService) => {
  authService.postUserLoad((user: User) => refinerService?.identifyUser(user));
  authService.postLogout(() => refinerService?.resetUser());
};
