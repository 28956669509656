import {createTopicTag, getTopicTag, getTopicTags} from '@/tasks/api/topic-tags';
import {ActionFactory} from '@/orm/api/actions';
import TaskTopicTag from '@/tasks/models/TaskTopicTag';

const factory = new ActionFactory(() => TaskTopicTag);

export const fetch = factory.insertAction(getTopicTags);
export const get = factory.insertAction(getTopicTag);
export const create = factory.insertAction(createTopicTag);
export const post = create;
