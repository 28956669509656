import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {IGetTopicTagsParams} from '@/tasks/types/IGetTopicTagsParams';

export const TOPIC_TAGS_BASE_URL = API_BASE_URL + '/topic-tags';

/**
 * Get many topic tags
 * @returns {IStembleAxiosPromise}
 * @param params
 */
export const getTopicTags = (params: IGetTopicTagsParams = {}): IStembleAxiosPromise => {
  return HTTP.get(TOPIC_TAGS_BASE_URL, {params});
};

/**
 * Get a single topic tag by ID
 * @param id
 * @returns {IStembleAxiosPromise}
 */
export const getTopicTag = (id: number): IStembleAxiosPromise => {
  return HTTP.get(`${TOPIC_TAGS_BASE_URL}/${id}`);
};

/**
 * Create a new topic tag
 * @param data
 * @returns {IStembleAxiosPromise}
 */
export const createTopicTag = (data: any): IStembleAxiosPromise => {
  return HTTP.post(TOPIC_TAGS_BASE_URL, data);
};

export default {
  getTopicTags,
  getTopicTag,
  createTopicTag,
};
