import {Fields, Model, Query} from '@vuex-orm/core';
import TaskResponse from './TaskResponse';
import TaskState from '../../task-states/models/TaskState';
import TaskTopicTag from './TaskTopicTag';
import Grade from '../../grades/models/Grade';
import IssuedTaskState from '../../task-states/models/IssuedTaskState';
import {Joined} from '@/orm/types/Joined';
import * as TaskApi from '@/tasks/api/orm/TaskApi';
import {ITaskQueryParameters} from '@/tasks/types/ITaskQueryParameters';
import {IVariableMapping} from '@/task-states/types/IVariableMapping';
import {RawTask} from '@/tasks/types/RawTask';
import TaskFeedback from '@/tasks/models/TaskFeedback';
import {TaskType} from '@/tasks/types/TaskType';
import {TaskMap} from '@/tasks/types/TaskMap';
import {TaskResponseType} from '@/tasks/types/TaskResponseType';
import {TaskScopeContent} from '@/tasks/models/TaskScopeContent';

/**
 * Task class
 */
export class Task<
    T extends TaskType = TaskType,
    ResponseType extends TaskResponseType = TaskResponseType,
    VariableMapping extends IVariableMapping = IVariableMapping,
  >
  extends Model
  implements Omit<RawTask<T>, 'content' | 'topicTags' | 'taskValues'>
{
  static entity = 'Task';

  id!: number;
  taskType!: T;
  content!: Joined<TaskMap[T]>;
  responses!: Joined<TaskResponse<ResponseType>[]>;
  grades!: Joined<Grade[]>;
  taskContent!: string | null;
  hintText!: string | null;
  taskStates!: Joined<TaskState<VariableMapping>[]>;
  issuedTaskStates!: Joined<IssuedTaskState<VariableMapping>[]>;
  title!: string;
  youtubeVideoId!: string | null;
  textbookLink!: string | null;
  topicIds!: number[];
  topicTagIds!: number[];
  topicTags!: Joined<TaskTopicTag[]>;
  manuallyGraded!: boolean;
  visibleInAssignment!: boolean;
  hasVariables!: boolean;
  taskFeedbacks!: Joined<TaskFeedback[]>;
  scopes!: Joined<TaskScopeContent[]>;
  isAiGraded!: boolean;
  _ancestorTopicIds!: number[];
  _ancestorTopicIdsSet!: Set<number>;
  _taskTypeIds!: number[];
  _taskTypeIdsSet!: Set<number>;
  draftable!: boolean;

  static fields(): Fields {
    return {
      id: this.number(null),
      taskType: this.string(''),
      status: this.string('published'),
      content: this.morphTo('id', 'taskType'),
      responses: this.hasMany(TaskResponse, 'taskId'),
      grades: this.hasMany(Grade, 'taskId'),
      taskContent: this.string('').nullable(),
      hintText: this.string('').nullable(),
      taskStates: this.hasMany(TaskState, 'taskId'),
      issuedTaskStates: this.hasMany(IssuedTaskState, 'taskId'),
      title: this.string(null),
      youtubeVideoId: this.string(null).nullable(),
      textbookLink: this.string(null).nullable(),
      topicTagIds: this.attr([]),
      topicTags: this.hasManyBy(TaskTopicTag, 'topicTagIds'),
      manuallyGraded: this.boolean(false),
      visibleInAssignment: this.boolean(true),
      hasVariables: this.boolean(false),
      topicIds: this.attr([]),
      ancestorTopicIds: this.attr([]),
      taskTypeIds: this.attr([]),
      taskFeedbacks: this.hasMany(TaskFeedback, 'taskId'),
      scopes: this.hasMany(TaskScopeContent, 'taskId'),
      isAiGraded: this.boolean(false),
      draftable: this.boolean(true),
    };
  }

  static get api() {
    return TaskApi;
  }

  get ancestorTopicIds() {
    return this._ancestorTopicIds;
  }

  set ancestorTopicIds(ancestorTopicIds: number[]) {
    this._ancestorTopicIds = ancestorTopicIds;
    this._ancestorTopicIdsSet = new Set<number>(ancestorTopicIds);
  }

  get ancestorTopicIdsSet() {
    return this._ancestorTopicIdsSet;
  }

  get taskTypeIds() {
    return this._taskTypeIds;
  }

  set taskTypeIds(taskTypeIds: number[]) {
    this._taskTypeIds = taskTypeIds;
    this._taskTypeIdsSet = new Set<number>(taskTypeIds);
  }

  get taskTypeIdsSet() {
    return this._taskTypeIdsSet;
  }

  /**
   * Build a query that fully joins all of the sub-relations
   * @returns {function(*): *}
   * @param options
   */
  static fullQuery(options: ITaskQueryParameters = {}): Query<Task> {
    let query: Query<Task> = options.query ?? this.query();

    query = query.with('content');

    if (options.taskStates || options.topicTags) {
      if (options.topicTags) {
        query = query.with('topicTags');
      }
      if (options.taskStates) {
        query = query
          .with('taskStates', (query4) => {
            return TaskState.fullQuery({
              query: query4 as Query<TaskState>,
            }).orderBy('createdAt');
          })
          .with('issuedTaskStates', (query5) => {
            return IssuedTaskState.fullQuery({
              query: query5 as Query<IssuedTaskState>,
            });
          });
      }
    }
    if (options.responses || options.feedback) {
      query = query.with('responses', (query2) => {
        return TaskResponse.fullQuery({
          feedback: options.feedback,
          query: query2 as Query<TaskResponse>,
        });
      });
    }
    if (options.grades) {
      query = query.with('grades');
    }
    if (options.taskFeedbacks) {
      query = query.with('taskFeedbacks');
    }
    if (options.scopes) {
      query = query.with('scopes', (q) => {
        return TaskScopeContent.fullQuery({query: q as Query<TaskScopeContent>});
      });
    }
    return query;
  }

  hasResponses() {
    return this.responses ? this.responses.length > 0 : false;
  }

  getResponsesForAssignment(assignmentId: number) {
    return this.responses.filter((response) => {
      return response.assignmentId == assignmentId;
    });
  }

  getResponsesForUserAssignment(userId: number, assignmentId: number) {
    return this.getResponsesForAssignment(assignmentId).filter((response) => {
      return response.userId == userId;
    });
  }

  getGradesForAssignment(assignmentId: number) {
    return this.grades.filter((grade) => {
      return grade.assignmentId == assignmentId;
    });
  }

  getGradesForUserAssignment(userId: number, assignmentId: number) {
    return this.getGradesForAssignment(assignmentId).filter((grade) => {
      return grade.userId == userId;
    });
  }

  hasVideo() {
    return this.youtubeVideoId !== null;
  }

  hasTextbookLink() {
    return this.textbookLink !== null;
  }

  isRandomizable() {
    return this.hasVariables;
  }

  isPreviewable() {
    return !this.manuallyGraded;
  }
}

export default Task;
