import {Changed} from '@/common/utils/getAllChanged';
import {BatchRequestDto} from '@/batch/types/BatchRequestDto';
import {BatchRequestElementDto} from '@/batch/types/BatchRequestElementDto';

export class BatchRequestBuilder<T, ID extends keyof T, K extends keyof T> {
  baseUrl!: string;
  idProperty!: ID;
  keyProperty!: K;
  gets: T[ID][] = [];
  posts: T[] = [];
  puts: T[] = [];
  patches: Partial<T>[] = [];
  deletes: T[] = [];

  constructor(baseUrl: string, idProperty: ID, requestKeyProperty: K) {
    this.baseUrl = baseUrl;
    this.idProperty = idProperty;
    this.keyProperty = requestKeyProperty;
  }

  withChanges(changes: Changed<T>): this {
    this.deletes = changes.removed;
    this.posts = changes.added;
    this.puts = changes.kept;
    return this;
  }

  withGets(gets: T[ID][]): this {
    this.gets = gets;
    return this;
  }

  withPosts(posts: T[]): this {
    this.posts = posts;
    return this;
  }

  withPuts(puts: T[]): this {
    this.puts = puts;
    return this;
  }

  withPatches(patches: Partial<T>[]): this {
    this.patches = patches;
    return this;
  }

  withDeletes(deletes: T[]): this {
    this.deletes = deletes;
    return this;
  }

  build(): BatchRequestDto<T> {
    const getRequests: BatchRequestElementDto<T>[] = (this.gets ?? []).map((get) => {
      return {
        key: String(get),
        method: 'GET',
        requestUrl: `${this.baseUrl}/${get}`,
      };
    });

    const postRequests: BatchRequestElementDto<T>[] = (this.posts ?? []).map((post) => {
      return {
        key: String(post[this.keyProperty]),
        method: 'POST',
        requestUrl: `${this.baseUrl}`,
        resource: post,
      };
    });

    const putRequests: BatchRequestElementDto<T>[] = (this.puts ?? []).map((put) => {
      return {
        key: String(put[this.keyProperty]),
        method: 'PUT',
        requestUrl: `${this.baseUrl}/${put[this.idProperty]}`,
        resource: put,
      };
    });

    const patchRequests: BatchRequestElementDto<T>[] = (this.patches ?? []).map((patch) => {
      return {
        key: String(patch[this.keyProperty]),
        method: 'PATCH',
        requestUrl: `${this.baseUrl}/${patch[this.idProperty]}`,
        resource: patch,
      };
    });

    const deleteRequests: BatchRequestElementDto<T>[] = (this.deletes ?? []).map((del) => {
      return {
        key: String(del[this.keyProperty]),
        method: 'DELETE',
        requestUrl: `${this.baseUrl}/${del[this.idProperty]}`,
      };
    });

    return {
      data: {
        batch: getRequests.concat(postRequests, putRequests, patchRequests, deleteRequests),
      },
    };
  }
}
